import "./HomePage.scss";

import {
    Container,
    ContainerSize,
    Headline,
    HeadlineType,
    Section,
    SectionHeadlinePosition,
    SectionType,
    Teaser,
    TeaserImagePosition,
    TeaserSize,
    TeaserTextPosition,
    Text,
    TextType,
} from "@alphaneun/ui-library";
import dayjs from "dayjs";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { Article } from "../../types/article";

export interface HomePageProps {
    articles: Article[];
}

export const HomePage: React.FC<HomePageProps> = ({ articles }) => {
    const images = useStaticQuery(graphql`
        query {
            jobs: file(relativePath: { eq: "index-teaser-jobs.jpg" }) {
                ...teaserImage
            }
            aboutus1: file(
                relativePath: { eq: "index-teaser-about-us-1.jpg" }
            ) {
                ...teaserImage
            }
            aboutus2: file(
                relativePath: { eq: "index-teaser-about-us-2.jpg" }
            ) {
                ...teaserImage
            }
        }
    `);

    return (
        <div className="HomePage">
            <Section
                className="HomePage__introSection"
                showWarpAnimation={true}
                showCloudAnimation={true}
                cloudStyles={{
                    transform: "scale(1.5)",
                }}
                warpStyles={{
                    right: "-150px",
                    top: "-200px",
                    transform: "scale(1.4) rotate(10deg)",
                    opacity: 0.5,
                }}
            >
                <Container size={ContainerSize.Default}>
                    <Headline type={HeadlineType.H1}>
                        Care. Craft. Excite.
                    </Headline>

                    <Text type={TextType.BodyHuge}>
                        Alpha Neun entwickelt seit über 10 Jahren innovative
                        Lösungen im Bereich Crawling &amp; Datenanalyse und
                        Services. Mit unserem Team betreiben wir unter Anderem
                        die lokale Suchmaschine "auskunft.de", die sich gerade
                        neu erfindet.
                    </Text>
                </Container>
            </Section>

            <Section type={SectionType.Ribbon} headline="JOBS">
                <Link to="/jobs" className="noStyles">
                    <Teaser
                        size={TeaserSize.Full}
                        imagePosition={TeaserImagePosition.Right}
                        image={
                            <GatsbyImage
                                alt=""
                                style={{ height: "100%" }}
                                // @ts-ignore
                                image={getImage(images.jobs)}
                            />
                        }
                        headline="Du bist großartig?"
                        text="Wir auch! Wir wollen wachsen und suchen vielleicht genau dich. Schau dir unsere offenen Stellen an oder bewirb dich initiativ bei uns."
                        link="Zu den Jobs"
                        textPosition={TeaserTextPosition.Bottom}
                    />
                </Link>
            </Section>

            <Section
                headline="WIR"
                headlinePosition={SectionHeadlinePosition.Right}
                showCloudAnimation={true}
                cloudStyles={{
                    left: "-250px",
                }}
            >
                <Teaser
                    size={TeaserSize.Full}
                    imagePosition={TeaserImagePosition.Left}
                    image={
                        <GatsbyImage
                            alt=""
                            style={{ height: "100%" }}
                            // @ts-ignore
                            image={getImage(images.aboutus1)}
                        />
                    }
                    headline="Wer sind wir eigentlich?"
                    text="Primär stehen wir hinter auskunft.de. Das ist das Flagship-Portal für alle Produkte, die wir entwickeln um die Digitalisierung von Klein- und Mittelständischen Unternehmen voran zu treiben."
                    textPosition={TeaserTextPosition.Bottom}
                />
            </Section>

            <Section
                headline=""
                headlinePosition={SectionHeadlinePosition.Right}
            >
                <Link to="/ueber-uns" className="noStyles">
                    <Teaser
                        size={TeaserSize.Full}
                        imagePosition={TeaserImagePosition.Right}
                        image={
                            <GatsbyImage
                                alt=""
                                style={{ height: "100%" }}
                                // @ts-ignore
                                image={getImage(images.aboutus2)}
                            />
                        }
                        headline="Was machen wir?"
                        text="Wir glauben an mehr als eine solide Basis und ein gutes Produkt. Wir wollen immer den einen Schritt weiter gehen, für die besonderen Ergebnisse, die über das Normale hinausgehen und den Nutzern Freude bringen, wenn sie unsere Produkte verwenden."
                        link="Mehr über uns"
                        textPosition={TeaserTextPosition.Bottom}
                    />
                </Link>
            </Section>

            <Section
                className="HomePage__magSection"
                headline="Mag"
                headlinePosition={SectionHeadlinePosition.Center}
            >
                {articles.map((article) => (
                    <Link
                        key={article.data.title}
                        to={"magazin/" + article.uid}
                    >
                        <Teaser
                            date={dayjs(article.last_publication_date).format(
                                "DD.MM.YYYY",
                            )}
                            size={TeaserSize.Default}
                            imagePosition={TeaserImagePosition.Right}
                            image={
                                <GatsbyImage
                                    alt=""
                                    // @ts-ignore
                                    image={getImage(
                                        article.data.featured_image.localFile
                                            .childImageSharp.gatsbyImageData,
                                    )}
                                />
                            }
                            title={article.data.title}
                            headline={article.data.title}
                            textPosition={TeaserTextPosition.Bottom}
                            withPointer={true}
                            teaserTextProps={{
                                wrapHeadlineInLink: true,
                            }}
                        />
                    </Link>
                ))}
            </Section>
        </div>
    );
};
