import { Stage, StageSize } from "@alphaneun/ui-library";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { HomePage } from "../components/home-page/HomePage";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const Home: React.FC<PageProps> = () => {
    const data = useStaticQuery(graphql`
        query IndexPageQuery {
            stageimage: file(relativePath: { eq: "index-stage.jpg" }) {
                ...fullSizeImage
            }
            articles: allPrismicArticle {
                nodes {
                    data {
                        title
                        category
                        author
                        content {
                            text {
                                richText
                            }
                            image {
                                localFile {
                                    ...constrainedImage
                                }
                            }
                        }
                        featured_image {
                            localFile {
                                ...fullSizeImage
                            }
                        }
                    }
                    _previewable
                    last_publication_date
                    uid
                }
            }
        }
    `);

    return (
        <Layout>
            <SEO />

            <Stage
                size={StageSize.Default}
                text="Hi"
                image={
                    <GatsbyImage
                        loading="eager"
                        alt=""
                        style={{ height: "100%" }}
                        // @ts-ignore
                        image={getImage(data.stageimage)}
                    />
                }
            ></Stage>

            <HomePage articles={data.articles.nodes} />
        </Layout>
    );
};

export default Home;
